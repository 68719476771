import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { ConfedApi, portalApi } from "../../api";
import FeatureBox from "../partials/feature-box";
import Gallery from "../partials/gallery";
import RankingListOne from "../partials/ranking-list/ranking-list-one";
import ScheduleOne from "../partials/schedule/schedule-one";
import GridNoticias from "./noticias/GridNoticias";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ufs } from "../../util/ufs";

export default function HomePage() {
  // const { dominio } = props
  // console.log(dominio);
  const { ufFederacao } = useParams();
const idFederacao = ufs[ufFederacao]

  // Utilizando useRef para verificar se o componente está montado
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false; // Marca o componente como desmontado
    };
  }, []);

  // Notícias
  const [noticias, setNoticias] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setNoticias({
      loading: true,
      error: false,
      payload: [],
    });

    async function getNoticias() {
      try {
        const response = await portalApi.get("noticia", {
          params: {
            flag_del: 0,
            flag_publicado: 1,
            id_estabelecimento: idFederacao, // Alterado aqui
            expand: "idEsporte",
            notNullField: "foto_capa",
            sort: "-created_at",
            lessField: "created_at",
            lessValue: new Date().getTime().toString().substr(0, 10),
            pageSize: 5,
          },
        });

        if (isMounted.current) {
          setNoticias({
            loading: false,
            error: response.data._meta.totalCount === 0,
            payload: response.data.items || [],
          });
        }
      } catch (err) {
        if (isMounted.current) {
          setNoticias({
            loading: false,
            error: true,
            payload: [],
          });
        }
        console.error(err);
      }
    }

    getNoticias();
  }, [idFederacao]);

  // Eventos
  const [eventos, setEventos] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setEventos({
      loading: true,
      error: false,
      payload: [],
    });

    async function getEventos() {
      try {
        const response = await ConfedApi.get("evento-regra", {
          params: {
            "evento.flag_del": 0,
            "evento.id_estabelecimento": idFederacao, // Alterado aqui
            expand: "evento,estabelecimento",
            forcejoin: "evento",
            sort: "-evento.data_inicio",
            betweenField: "evento.data_fim",
            betweenValue1: moment().format("YYYY-MM-DD"),
            betweenValue2: moment().add(2, "M").format("YYYY-MM-DD"),
            pageSize: 5,
          },
        });

        if (isMounted.current) {
          setEventos({
            loading: false,
            error: response.data._meta.totalCount === 0,
            payload: response.data.items || [],
          });
        }
      } catch (err) {
        if (isMounted.current) {
          setEventos({
            loading: false,
            error: true,
            payload: [],
          });
        }
        console.error(err);
      }
    }

    getEventos();
  }, [idFederacao]);

  // Galeria
  const [galeria, setGaleria] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setGaleria({
      loading: true,
      error: false,
      payload: [],
    });

    async function getGaleria() {
      try {
        const response = await portalApi.get("galeria-fotos", {
          params: {
            sort: "-created_at",
            pageSize: 12,
          },
        });

        if (isMounted.current) {
          setGaleria({
            loading: false,
            error: response.data._meta.totalCount === 0,
            payload: response.data.items || [],
          });
        }
      } catch (err) {
        if (isMounted.current) {
          setGaleria({
            loading: false,
            error: true,
            payload: [],
          });
        }
        console.error(err);
      }
    }

    getGaleria();
  }, []);

  // Ranking
  const [ranking, setRanking] = useState({
    loading: true,
    error: false,
    payload: [],
  });
  const [rankingData, setRankingData] = useState("");
  const [idCategoria, setIdCategoria] = useState(1);

  useEffect(() => {
    async function getLastRanking() {
      try {
        const response = await ConfedApi.get("ranking-historico", {
          params: {
            sort: "-data",
            pageSize: 1,
          },
        });

        if (isMounted.current) {
          setRankingData(
            response.data._meta.totalCount === 0 ? "" : response.data.items[0].data
          );
        }
      } catch (err) {
        console.error(err);
      }
    }

    getLastRanking();
  }, []);

  useEffect(() => {
    if (rankingData) {
      setRanking({
        loading: true,
        error: false,
        payload: [],
      });

      async function getRankingHistorico() {
        try {
          const response = await ConfedApi.get("ranking-historico/colocacao", {
            params: {
              data: moment(rankingData).format("YYYY-MM").concat("-01"),
              "rankingCategorias.id": idCategoria,
              pageSize: 10,
            },
          });

          if (isMounted.current) {
            setRanking({
              loading: false,
              error: response.data._meta.totalCount === 0,
              payload: response.data.items || [],
            });
          }
        } catch (err) {
          if (isMounted.current) {
            setRanking({
              loading: false,
              error: true,
              payload: [],
            });
          }
          console.error(err);
        }
      }

      getRankingHistorico();
    }
  }, [rankingData, idCategoria]);

  const [rankingCategorias, setRankingCategorias] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setRankingCategorias({
      loading: true,
      error: false,
      payload: [],
    });

    async function getRankingCategorias() {
      try {
        const response = await ConfedApi.get("ranking-categorias", {
          params: {
            pageSize: 40,
          },
        });

        if (isMounted.current) {
          setRankingCategorias({
            loading: false,
            error: response.data._meta.totalCount === 0,
            payload: response.data.items || [],
          });
        }
      } catch (err) {
        if (isMounted.current) {
          setRankingCategorias({
            loading: false,
            error: true,
            payload: [],
          });
        }
        console.error(err);
      }
    }

    getRankingCategorias();
  }, []);

  const handleChangeCategoria = (idCategoria) => {
    setIdCategoria(idCategoria);
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SITETITLE}</title>
      </Helmet>
      <main className="main home mt-5">
        <GridNoticias noticias={noticias} />
        <ScheduleOne eventos={eventos} />
        <div className="container-fluid position-relative ranking-section">
          <RankingListOne
            ranking={ranking}
            rankingData={rankingData}
            handleChangeCategoria={handleChangeCategoria}
            rankingCategorias={rankingCategorias}
            idCategoria={idCategoria}
          />
        </div>
        <Gallery galeria={galeria} />
        <FeatureBox />
      </main>
    </>
  );
}
