export const ufs = {
    ba: 9,
    es: 15,
    ce: 12,
    mt: 10,
    ms: 7,
    sc: 2,
    df: 5,
    pa: 13,
    pr: 16,
    rj: 6,
    rs: 11,
    go: 14,
    mg: 4,
    sp: 3,
    pe: 8
}