import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import Layout from "./components/common/layout";

import "./style.scss";

import HomePage from "./components/pages";
import Eventos from "./components/pages/eventos";
import Noticias from "./components/pages/noticias";
import Documentos from "./components/pages/documentos";
import SingleNoticias from "./components/pages/noticias/single";
import Transparencia from "./components/pages/transparencia";
import Aleta from "./components/pages/aleta";
import Ranking from "./components/pages/ranking";
import BolicheEvento from "./components/pages/boliche-evento";
import ScrollToTop from "./util/ScrollToTop";
import Galerias from "./components/pages/galerias";
import GaleriaItem from "./components/pages/galeria-item";
import PageEmBreve from "./components/pages/em-breve";
import ContatoPage from "./components/pages/contato";
import PageNotFound from "./components/pages/not-found";
import PageFederacao from "./components/pages/federacao";
import OrgaoPage from "./components/pages/orgao-page";
import BolicheMidia from "./components/pages/noticias/boliche-midia";
// import { useEffect, useRef, useState } from "react";
// import { getDominio } from "./util/getDominio";
// import HomeFederacao from "./components/pages/home-fed";
// import CountdownOlimpiadas from "./components/CountdownOlimpiadas";

// const defDomain = {hostname: '', conf: {id:0,sigla:'',uf:'',descricao:''}}

function App() {
  // const [dominio, setDominio] = useState(defDomain)

  // const isMounted = useRef(true);

  // useEffect(() => {
  //   // Marca o componente como montado
  //   isMounted.current = true;

  //   // Recupera o domínio
  //   const dominio = getDominio();

  //   // Atualiza o estado apenas se o componente ainda estiver montado
  //   if (isMounted.current) {
  //     setDominio(dominio);
  //   }

  //   // Cleanup para marcar o componente como desmontado
  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        {/* <CountdownOlimpiadas /> */}
        <Layout>
          <Switch>
            <Route path="/" exact>
            <HomePage />
            </Route>
            <Route path="/federacao/:ufFederacao" exact component={HomePage} />
            <Route path="/eventos" exact>
              <EventosParams />
            </Route>
            <Route
              path="/evento/:idEvento/:slug"
              exact
              component={BolicheEvento}
            />
            <Route path="/noticias" exact component={Noticias} />
            <Route path="/boliche-na-midia" exact component={BolicheMidia} />
            <Route path="/documentos" exact component={Documentos} />
            <Route
              path="/noticias/leitura/:id/:slug"
              component={SingleNoticias}
            />
            <Route path="/transparencia" exact component={Transparencia} />
            <Route path="/atleta/:id/:slug" exact component={Aleta} />
            <Route path="/ranking" exact component={RankingParams} />
            <Route path="/galerias" exact component={GaleriaParams} />
            <Route path="/galeria/:id/:slug" component={GaleriaItemParams} />
            <Route path="/contato" exact>
              <ContatoPage />
            </Route>
            <Route path="/federacoes">
              <PageFederacao />
            </Route>
            <Route path="/:menuPai/:idMenu/:url" exact>
              <OrgaoPage />
            </Route>
            <Route path="/em-breve" exact>
              <PageEmBreve />
            </Route>
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EventosParams() {
  let query = useQuery();
  return (
    <Eventos
      queryParam={query.get("q")}
      page={query.get("p")}
      temporada={query.get("temporada")}
      escopo={query.get("escopo")}
    />
  );
}

function RankingParams() {
  let query = useQuery();
  return <Ranking queryParam={query.get("q")} />;
}

function GaleriaParams() {
  let query = useQuery();
  return <Galerias page={query.get("p")} esporte={query.get("esporte")} />;
}
function GaleriaItemParams() {
  let query = useQuery();
  return <GaleriaItem page={query.get("p")} />;
}
