import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import EstadoFlag from '../../../assets/img/flags/EstadosFlag';
import { gestaoApi } from '../../../api';
import Breadcrumbs from '../../common/Breadcrumbs'
import { Card, CardContent, Typography } from '@mui/material';

export default function PageFederacao() {

    const [federacoes, setFederacoes] = useState({
        loading: true,
        error: false,
        payload: []
    });

    useEffect(() => {
        setFederacoes({
            loading: true,
            error: false,
            payload: []
        })
        async function getFederacoes() {
            await gestaoApi.get('estabelecimento', {
                params: {
                    id_estabelecimento_tipo: 2,
                    sort:'descricao',
                    pageSize: 100,
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setFederacoes({
                        loading: false,
                        error: true,
                        payload: []
                    })
                } else {
                    setFederacoes({
                        loading: false,
                        error: false,
                        payload: response.data.items
                    })
                }
            });
        }
        getFederacoes()
    }, [])

    const breadcrumb = [
        { label: "Federações", url: "/federacoes" }
    ]

    return (
        <>
            <Helmet>
                <title>Federações - {process.env.REACT_APP_SITETITLE}</title>
            </Helmet>
            <Breadcrumbs paths={breadcrumb} />
            <main className="main noticias">
                <div className="ranking-list ranking-list-one">
                    <div className="container">
                        <div className="list-header">
                            <div className="section-title mb-5">
                                <h2 className="text-secondary-light">Federações</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mb-5">
                    {federacoes.error ? "" :
                        <div>
                            {federacoes.loading ? "" :
                                <div className="row">
                                    {federacoes.payload.map((item, key) => (
                                        <div key={key} className="col-lg-6">
                                            <a href={`/federacao/${item.uf.toLowerCase()}`}>
                                                <Card className="card-federacao" variant="outlined">
                                                    <CardContent>
                                                        <div className='d-flex'>
                                                            <div className='mr-3'>
                                                                <EstadoFlag estado={item.uf} />
                                                            </div>
                                                            <div>
                                                                <Typography component="h4" className="card-title" gutterBottom>
                                                                    {item.descricao}
                                                                </Typography>
                                                                <Typography variant="h5" color="inherit" gutterBottom>
                                                                    <b>Presidente:</b> {item.presidente}
                                                                </Typography>
                                                                <Typography variant="h5" color="inherit" gutterBottom>
                                                                    <b>Email:</b> {item.email}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }
                </div>
            </main>
        </>
    )
}
